<template>
    <b-container class="text-center">
        <div class="d-flex justify-content-center mb-1">
            <b-spinner variant="success" label="Spinning" block v-if="loading"
                style="width: 3rem; height: 3rem"></b-spinner>
        </div>
        <div class="wrapper_service" v-if="!loading">
            <div class="select text-center">
            <h3 class="text-primary">Select your service</h3>
        </div>
        <b-form-select v-model="selected" :options="optionsProduct" @change="getService()" />
        </div>
    </b-container>
</template>
  
<script>
import {
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormGroup,
    BFormTextarea,
    BSpinner,
    BContainer,
    BFormSelect,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";

export default {
    components: {
        BFormTextarea,
        BFormSelect,
        BContainer,
        BInputGroup,
        BFormGroup,
        BFormInput,
        BSpinner,
        BInputGroupPrepend,
        BInputGroupAppend,
    },

    computed: {
        ...mapGetters("productModule", {
            ProductList: "product",
            loading: "loading",
        }),
    },
    data() {
        return {
            selected: null,

            optionsProduct: [],
        };
    },

    methods: {
        ...mapActions("productModule", ["getProductListAction"]),

        getService() {
            this.$emit("templateSelected", this.selected);
        },
    },
    async mounted() {
        await this.getProductListAction().then(() => {
            this.ProductList.map((v, i) => {
                if (v.type === 4) {
                    this.optionsProduct.push({
                        value: v.id,
                        text: `${v.name}`,
                    });
                }
            });
        });
    },
};
</script>
  
<style></style>
  