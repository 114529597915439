<template>
    <b-form >
        <b-row>
            <b-col cols="12">
                <b-form-group label="Additional Cost (Rs)" label-for="cost" label-cols-md="4">
                    <b-form-input id="cost" type="number" placeholder="Rs 6000" v-model="price.additionalCost"
                        @change="calculatePrice" />

                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group label="Previous Selling Price (Rs)"  label-for="price" label-cols-md="4">
                    <b-form-input id="price" type="number" placeholder="Rs 8280" v-model="PrevioussalePrice" readonly />
                </b-form-group>
            </b-col>

            <b-col cols="12">
                <b-form-group label="New Selling Price (Rs)" label-for="price" label-cols-md="4">
                    <b-form-input id="price" type="number" placeholder="Rs 8280" v-model="productID.salePrice" readonly />
                </b-form-group>
            </b-col>
        </b-row>

    </b-form>
</template>


<script>

import { BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BFormGroup, BFormTextarea, BContainer, BFormSelect, BForm, BRow, BCol } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";

export default {

    components: {
        BFormTextarea,
        BFormSelect,
        BContainer,
        BInputGroup,
        BFormGroup,
        BFormInput,
        BInputGroupPrepend,
        BInputGroupAppend,
        BForm,
        BRow,
        BCol,
    },


    data() {
        return {
            PrevioussalePrice: null,
            price: {
                NewPrice: null,
                additionalCost: null,
            }
        }
    },
    props: {
        product: {
            type: String,
            required: true
        }
    },

    watch: {
    product: {
        handler: 'productChanged',
        immediate: true,
    },
    'price.additionalCost': {
        handler: 'calculatePrice',
        immediate: true,
    },
},
    computed: {
        ...mapGetters("productModule", {
            productID: "productID",
            loading: "loading",
        }),
    },

    methods: {
        ...mapActions("productModule", ["getProductIDAction"]),
        productChanged(newVal, oldVal) {
            this.getProductIDAction(newVal).then(() => {
                this.PrevioussalePrice = this.productID.salePrice;
            });
        },
    
        calculatePrice() {
            if (this.price.additionalCost === null || this.price.additionalCost === 0) {
                this.productID.salePrice = this.PrevioussalePrice;
                this.price.NewPrice = this.PrevioussalePrice;
             
            } else {
                this.productID.salePrice = Number(this.PrevioussalePrice) + Number(this.price.additionalCost);
                this.price.NewPrice = this.productID.salePrice;
             
            }
            this.$emit('priceUpdated', {
            salePrice: this.productID.salePrice,
            additionalCost: this.price.additionalCost,
        });
        }
    },


}
</script>