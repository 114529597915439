<template>
  <section class="invoice-add-wrapper">
    <div class="d-flex justify-content-center mb-1">
      <b-spinner variant="success" label="Spinning" block v-if="loading" style="width: 3rem; height: 3rem"></b-spinner>
    </div>
    <b-form @submit.prevent="submitForm" @change="submitFormText" v-if="!loading">
      <b-row class="invoice-add">
        <b-col cols="12" xl="12" md="12">
          <b-card no-body class="invoice-preview-card">
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">
              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                <!-- Header: Left Content -->
                <!-- <Logo /> -->

                <!-- Header: Right Content -->
                <div class="invoice-number-date mt-md-0 mt-2">
                  <div class="d-flex align-items-center mb-1">
                    <span class="title">Start Date: </span>
                    <flat-pickr v-model="template.startDate" required class="form-control invoice-edit-input" />
                  </div>
                  <div class="d-flex align-items-center">
                    <span class="title"> Due Date: </span>
                    <flat-pickr v-model="template.dueDate" required class="form-control invoice-edit-input" />
                  </div>
                </div>
              </div>
            </b-card-body>
            <b-card-body class="invoice-padding pb-0">
              <b-row>
                <!-- Col: Sales Persion -->
                <b-col cols="12" md="6" class="mt-md-0 mt-3 d-flex align-items-center" order="2" order-md="1">
                  <label for="invoice-data-sales-person" class="text-nowrap mr-50">Staff:</label>
                  <b-form-select v-model="template.salesPersonId" :options="optionsSalesPerson" required />
                </b-col>

                <!-- Col: Total -->
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <!-- Invoice Client & Payment Details -->
            <h4 class="my-2 d-flex justify-content-start mx-3">
              <span>{{ product.description }}</span>
            </h4>

            <!-- Items Section -->
            <b-card-body class="invoice-padding form-item-section">
              <!-- <b-form-textarea v-model="template.text" rows="25" /> -->
              <quill-editor v-model="template.template" :options="editorOption" @change="submitFormText()">
                <div id="toolbar" slot="toolbar">
                  <button class="ql-bold">Bold</button>
                  <button class="ql-italic">Italic</button>
                  <button class="ql-underline">Underline</button>
                  <button class="ql-strike">Strike</button>
                  <button class="ql-blockquote">Quote</button>
                  <button class="ql-list" value="ordered"></button>
                  <button class="ql-list" value="bullet"></button>
                  <button class="ql-indent" value="-1"></button>
                  <button class="ql-indent" value="+1"></button>
                  <button class="ql-script" value="sub"></button>
                  <button class="ql-script" value="super"></button>
                  <button class="ql-background"></button>
                  <select class="ql-font">
                    <option selected="selected"></option>
                    <option value="serif">serif</option>
                    <option value="monospace">monospace</option>
                  </select>
                  <select class="ql-header">
                    <option value="1">Heading 1</option>
                    <option value="2">Heading 2</option>
                    <option value="3">Heading 3</option>
                    <option value="4">Heading 4</option>
                    <option value="5">Heading 5</option>
                    <option value="6">Heading 6</option>
                    <option selected></option>
                  </select>
                  <select class="ql-align">
                    <option selected></option>
                    <option value="center"></option>
                    <option value="right"></option>
                    <option value="justify"></option>
                  </select>
                </div>
              </quill-editor>
            </b-card-body>

            <b-card-body class="invoice-padding form-item-section" title="Note for Service Request"
              style="text-align: left">
              <!-- <b-form-textarea v-model="template.note" rows="5" /> -->
              <quill-editor v-model="template.notes" :options="editorOptionNote" @change="submitFormText()">
                <div id="toolbar-note" slot="toolbar">
                  <button class="ql-bold">Bold</button>
                  <button class="ql-italic">Italic</button>
                  <button class="ql-underline">Underline</button>
                  <button class="ql-strike">Strike</button>
                  <button class="ql-blockquote">Quote</button>
                  <button class="ql-list" value="ordered"></button>
                  <button class="ql-list" value="bullet"></button>
                  <button class="ql-indent" value="-1"></button>
                  <button class="ql-indent" value="+1"></button>
                  <button class="ql-script" value="sub"></button>
                  <button class="ql-script" value="super"></button>
                  <button class="ql-background"></button>
                  <select class="ql-font">
                    <option selected="selected"></option>
                    <option value="serif">serif</option>
                    <option value="monospace">monospace</option>
                  </select>
                  <select class="ql-header">
                    <option value="1">Heading 1</option>
                    <option value="2">Heading 2</option>
                    <option value="3">Heading 3</option>
                    <option value="4">Heading 4</option>
                    <option value="5">Heading 5</option>
                    <option value="6">Heading 6</option>
                    <option selected></option>
                  </select>
                  <select class="ql-align">
                    <option selected></option>
                    <option value="center"></option>
                    <option value="right"></option>
                    <option value="justify"></option>
                  </select>
                </div>
              </quill-editor>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />
          </b-card>
        </b-col>
      </b-row>
    </b-form>

    <!-- Right Col: Card -->

    <!-- Action Buttons -->
  </section>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import { quillEditor } from "vue-quill-editor";

import Ripple from "vue-ripple-directive";

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BFormSelect,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  BSpinner,
  VBToggle,
} from "bootstrap-vue";

import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { mapGetters, mapActions } from "vuex";
import Quill from "quill";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BSpinner,
    BFormSelect,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    flatPickr,
    vSelect,
    Logo,
    quillEditor,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      startDate: new Date(),
      dueDate: null,
      textAreaRows: 1,

      salesPerson: "",
      optionsSalesPerson: [],
      template: {
        name: "",
        subject: "",
        description: "",
        startDate: new Date(),
        dueDate: null,
        salesPersonId: "",
        template: "",
        notes: "",
      },
      editorOption: {
        modules: {
          toolbar: "#toolbar",
        },
      },
      editorOptionNote: {
        modules: {
          toolbar: "#toolbar-note",
        },
      },
    };
  },

  props: {
    templateId: {
      type: String,
      default: null,
    },
  },
  watch: {
    templateId: {
      handler: "templateIdChanged",
      immediate: true,
    },
  },
  computed: {
    ...mapGetters("salesPersonModule", {
      SalesPersonList: "salesperson",
      loading: "loading",
    }),
    ...mapGetters("productModule", {
      product: "productID",
      productLoading: "loading",
    }),
    renderedTemplate: {
      get() {
        return this.template && this.template.template ? this.template.template.replace(/\n/g, "<br>") : '';
      },
      set(newVal) {
        this.template.template = newVal.replace(/<br>/g, "\n");
      }
    }
  },
  async mounted() {
    await this.getProductIDAction(this.templateId).then(() => {
      this.template.template = this.product?.letterDescription?.replace(/\n/g, "<br>") ?? "";
    });

    await this.getSalesPersonListAction().then(() => {
      this.SalesPersonList.map((v, i) =>
        this.optionsSalesPerson.push({
          value: v.id,
          text: `${v.firstName} ${v.lastName}`,
        })
      );
    });
  },

  methods: {
    ...mapActions("salesPersonModule", ["getSalesPersonListAction"]),
    ...mapActions("productModule", ["getProductIDAction"]),
    templateIdChanged(newVal, oldVal) {
      this.getProductIDAction(newVal).then(() => {
        this.template.template = this.product?.letterDescription?.replace(/\n/g, "<br>") ?? "";
        console.log(this.template);
        this.submitFormText();
      });
    },

    submitFormText() {
      this.$emit("onchange", this.template);
    },
    getSelectedSalesPerson(event) {
      this.template.salesPersonId = event;
      this.submitFormText();
    },

  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.text-area-auto-height {
  height: auto;
}

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {

  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>
