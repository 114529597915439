<template>
    <div>
        <form-wizard color="#B1D06C" :title="null" :subtitle="null" shape="square" finish-button-text="Submit"
            back-button-text="Previous" class="mb-3" @on-complete="formSubmitted" ref="formWiz">
            <!-- Search customer tab -->
            <tab-content title="Select your customer">
                <SelectCustomer @customerSelected="customerSelected" />
            </tab-content>
            <tab-content title="Select your service">
                <SelectTemplate @templateSelected="templateSelected" />
            </tab-content>
            <tab-content title="Modify your template">
                <b-container class="text-center">
                    <TemplateView :templateId="templateId" @onchange="template" />
                </b-container>
            </tab-content>
            <tab-content title="Modify Price & Submit">
                <Price :product="templateId" @priceUpdated="updatePrice" />
            </tab-content>

        </form-wizard>
    </div>
</template>
  
<script>
import SelectCustomer from './selectCustomer.vue'
import SelectTemplate from './SelectTemplate.vue'
import TemplateView from './TemplateView.vue'
import Price from './Price.vue'
import { FormWizard, TabContent } from "vue-form-wizard";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { BRow, BCol, BButton } from "bootstrap-vue";
import { codeIcon } from "./LeadsWizard.js";
import { mapGetters, mapActions } from "vuex";
import { codeLinks } from '@/components/toasts/code';

export default {
    components: {
        FormWizard,
        BButton,
        SelectCustomer,
        SelectTemplate,
        TemplateView,
        Price,
        TabContent,
        BRow,
        BCol,
        ToastificationContent,
    },
    data() {
        return {
            codeIcon,
            customerId: "",
            templateId: "",
            templateData: {
                name: null,
                startDate: null,
                dueDate: null,
                salesPersonId: null,
                text: null,
                template: null,
                notes: null,
            },
            price: {
                salePrice: null,
                PrevioussalePrice: null,
                additionalCost: null,
            }
        };
    },
    computed: {
        ...mapGetters("productModule", {
            productID: "productID",
            loading: "loading",
        }),
        ...mapGetters("serviceModule", {
            loading: "loading",
        }),
    },
    methods: {
        ...mapActions("productModule", ["getProductIDAction"]),
        ...mapActions("serviceModule", ["addServiceRequestsAction"]),
        //route pages to leads

        customerSelected(customerSelected) {
            this.customerId = customerSelected;
        },
        templateSelected(templateSelected) {
            this.templateId = templateSelected;
        },
        template(template) {
            
            this.templateData.name = template.name;
            this.templateData.startDate = template.startDate;
            this.templateData.dueDate = template.dueDate;
            this.templateData.salesPersonId = template.salesPersonId;
            this.templateData.template = template.template;
            this.templateData.notes = template.notes;
        },
        updatePrice(price) {
            this.price.salePrice = price.salePrice;
            this.price.additionalCost = price.additionalCost;
            this.price.PrevioussalePrice = price.PrevioussalePrice;
        },
        error(errorResponse) {
            let errorMessage = "An unknown error occurred!";

            if (errorResponse && errorResponse.data && errorResponse.data.title) {
                errorMessage = errorResponse.data.title;

                // Optionally, add the specific field errors if they exist
                if (errorResponse.data.errors) {
                    for (let field in errorResponse.data.errors) {
                        errorMessage += "\n" + field + ": " + errorResponse.data.errors[field];
                    }
                }
            }

            this.$swal({
                title: "Error!",
                text: errorMessage,
                icon: "error",
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
            });
        },

        formSubmitted() {


            let payload = {
                name: this.templateData.name,
                startDate: this.templateData.startDate,
                dueDate: this.templateData.dueDate,
                assignedTo: this.templateData.salesPersonId,
                status: 0,
                salePrice: this.price.salePrice,
                additionalCost: this.price.additionalCost,
                totalCost: this.price.salePrice,
                leadClientId: this.customerId,
                itemId: this.templateId,
                template: this.templateData.template,
                notes: this.templateData.notes,
                isActive: true,
            };
            this.addServiceRequestsAction(payload)
                .then(() => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Service Request Added",
                            icon: "EditIcon",
                            variant: "success",
                        },
                    }),
                        this.$router.push({ name: "request-list" });
                })
                .catch((error) => {
                    console.log(error);
                    this.error(error.response);

                });
        },
    },
};
</script>
  
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
  